import { NavLink } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import Image from '../UI/Image'

function HeaderLogo() {
  const isMobile = useMediaQuery({
    query: '(max-width : 960px)',
  })

  return (
    <NavLink to="/">
      {isMobile ? (
        <Image name="header-textlogo" style={{ width: '69px', height: '24px' }} alt="logo" />
      ) : (
        <Image name="header-logo" style={{ width: '136px', height: '32px' }} alt="logo" />
      )}
    </NavLink>
  )
}

export default HeaderLogo
