import { ReactComponent as Facebook } from '../../images/footer-icon/ico-24-footer-facebook.svg'
import { ReactComponent as Twitter } from '../../images/footer-icon/ico-24-footer-twitter.svg'
import { ReactComponent as Telegram } from '../../images/footer-icon/ico-24-footer-telegram.svg'
import { ReactComponent as KakaoTalk } from '../../images/footer-icon/ico-24-footer-kakaotalk.svg'
import { ReactComponent as GitBook } from '../../images/footer-icon/ico-24-footer-gitbook.svg'
import { ReactComponent as GitHub } from '../../images/footer-icon/ico-24-footer-github.svg'
import { ReactComponent as Reddit } from '../../images/footer-icon/ico-24-footer-reddit.svg'

function FooterItem({ name }) {
  let href
  let image

  switch (name) {
    case 'facebook':
      href = 'https://www.facebook.com/thesixnetwork'
      image = <Facebook />
      break
    case 'twitter':
      href = 'https://twitter.com/DefinixOfficial'
      image = <Twitter />
      break
    case 'telegram':
      href = 'https://t.me/SIXNetwork'
      image = <Telegram />
      break
    case 'kakaotalk':
      href = 'https://open.kakao.com/o/gsh5pWGd'
      image = <KakaoTalk />
      break
    case 'gitbook':
      href = 'https://sixnetwork.gitbook.io/definix/'
      image = <GitBook />
      break
    case 'github':
      href = 'https://github.com/thesixnetwork'
      image = <GitHub />
      break
    case 'reddit':
      href = 'https://www.reddit.com/r/sixnetwork/'
      image = <Reddit />
      break
    default:
      break
  }

  return (
    <li className="ml-4 mb:ml-0">
      <a href={href}>{image}</a>
    </li>
  )
}

export default FooterItem
