import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import HeaderLogo from './HeaderLogo'
import HeaderLangs from './HeaderLangs'
import HeaderNavigation from './HeaderNavigation'
import LinkButton from '../UI/LinkButton'
import Backdrop from '../UI/Backdrop'
import UIButton from '../UI/UIButton'
import HeaderVersionButton from './HeaderVersionButton'

function Header() {
  const [showMenu, setShowMenu] = useState(false)
  const isMobile = useMediaQuery({
    query: '(max-width : 960px)',
  })

  const closeMenuHandler = () => {
    setShowMenu(false)
  }

  useEffect(() => {
    window.addEventListener('resize', closeMenuHandler)
    return () => {
      window.removeEventListener('resize', closeMenuHandler)
    }
  }, [])

  return (
    <header className="sticky z-30 top-0 bg-white after:block after:bg-pale after:absolute after:bottom-0 after:w-full after:h-px after:bg-opacity-20">
      <div className="flex justify-between items-center max-w-screen-xl mx-auto h-22 px-10 mb:justify-center mb:h-14 mb:px-5">
        <div className="flex items-center">
          <HeaderLogo />
          {/* {!isMobile && <HeaderVersionButton />} */}
        </div>

        {showMenu && <Backdrop onClick={closeMenuHandler} />}

        <div
          className={`${
            showMenu ? 'mb:-translate-x-0 duration-300' : 'mb:-translate-x-full'
          } flex items-center bg-white z-20 mb:px-5 mb:fixed mb:inset-y-0 mb:left-0 mb:w-76 mb:flex-col-reverse mb:justify-end mb:items-start mb:after:bg-grey-light mb:after:absolute mb:after:top-48 mb:after:left-0 mb:after:w-full mb:after:h-px mb:after:bg-opacity-50`}
        >
          {/* {isMobile && <HeaderVersionButton />} */}
          <HeaderNavigation onClick={closeMenuHandler} />
          <HeaderLangs />
          <div className="flex justify-center ml-4 mb:ml-0 mb:h-38 mb:self-center">
            <LinkButton className="mb:h-10 mb:w-46 mb:mt-26" />
          </div>
          <UIButton className="top-5 right-5" type="close" onClick={closeMenuHandler} />
        </div>
      </div>

      <UIButton className="ml-5 top-1/2 -translate-y-1/2" type="menu" onClick={() => setShowMenu(true)} />
    </header>
  )
}

export default Header
