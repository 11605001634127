import { Trans, useTranslation } from 'react-i18next'

import LandingChoiceItem1 from './LandingChoiceItem1'
import LandingChoiceItem2 from './LandingChoiceItem2'
import LandingSectionContainer from './LandingSectionContainer'

function LandingChoice() {
  const { t } = useTranslation()

  return (
    <LandingSectionContainer className="bg-yellow-light">
      <h3 className="text-3xl font-bold mb-10 mb:text-xl mb:mb-6">
        <Trans
          i18nKey="Definix is the best choice"
          components={[<span className="text-sub">the best choice</span>]}
        ></Trans>
      </h3>

      <div className="flex flex-wrap justify-center gap-6 mb-15">
        <LandingChoiceItem1
          title={t('Crypto investors')}
          content={t('who want to start investing')}
          image="landing-choice-1-crypto"
        />
        <LandingChoiceItem1
          title={t('Professional traders')}
          content={t('who want to have their')}
          content2={t('Participants must pass the Finix')}
          image="landing-choice-1-traders"
        />
      </div>

      <div className="flex flex-wrap justify-center gap-6 mb:justify-around">
        <LandingChoiceItem2
          title={t('Liquidity Providing & Farming')}
          content={t('For crypto investors, trading cryptocurrencies')}
          image="landing-choice-2-liquidity"
        />
        <LandingChoiceItem2
          title={t('Invest Confidently in Professional Managed Funds')}
          content={t("No worries if you don't know")}
          image="landing-choice-2-investment"
        />
        <LandingChoiceItem2
          title={t('User-Generated Crypto Funds')}
          content={t('For high-skilled traders')}
          image="landing-choice-2-crypto"
        />
      </div>
    </LandingSectionContainer>
  )
}

export default LandingChoice
