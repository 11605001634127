import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ArrowIcon } from '../../images/ui/ico-16-arrow-bottom-g.svg'

const LANG_OPTIONS = ['en', 'ko', 'th']

function HeaderLangs() {
  const [showLangs, setShowLangs] = useState(false)
  const { i18n } = useTranslation()

  const changeI18nLang = useCallback(
    (lng = 'ko') => {
      i18n.changeLanguage(lng)
    },
    [i18n],
  )

  const getLanguageText = (langCode) => {
    switch (langCode) {
      case 'en':
        return 'English'
      case 'ko':
        return '한국어'
      case 'th':
        return 'ไทย'
      default:
        return 'English'
    }
  }

  const changeLangHandler = (lang) => {
    if (lang !== i18n.language.slice(0, 2)) changeI18nLang(lang)
    setShowLangs(false)
  }

  useEffect(() => {
    const clickHandler = (e) => {
      if (!e.target.matches('.lang *')) {
        setShowLangs(false)
      }
    }

    window.addEventListener('click', clickHandler)
    return () => {
      window.removeEventListener('click', clickHandler)
    }
  }, [])

  // useEffect(() => {
  //   let targetFont

  //   switch (i18n.language.slice(0, 2)) {
  //     case 'en':
  //       targetFont = 'Roboto, sans-serif'
  //       break
  //     case 'ko':
  //       targetFont = 'Roboto, Noto Sans KR, sans-serif'
  //       break
  //     case 'th':
  //       targetFont = 'Roboto, Noto Sans Thai, sans-serif'
  //       break

  //     default:
  //       break
  //   }
  //   document.body.style.fontFamily = targetFont
  // }, [i18n.language])

  return (
    <div className="lang relative ml-7 mb:ml-0 mb:absolute mb:bottom-5">
      <div
        className="flex justify-between items-center pl-4 pr-3 py-2.5 min-w-26 cursor-pointer rounded-lg bg-white border border-solid border-grey-light bg-opacity-20 overflow-hidden hover:bg-pale hover:bg-opacity-20"
        onClick={() => setShowLangs((prev) => !prev)}
      >
        <button className="font-bold text-sm text-grey-dark hover:font-bold">
          {getLanguageText(i18n.language.slice(0, 2))}
        </button>
        <ArrowIcon className={`ml-3 ${showLangs && 'rotate-180'}`} />
      </div>

      {showLangs && (
        <div
          className="absolute min-w-26 top-11 left-1/2 -translate-x-1/2 rounded-lg bg-white border border-solid border-grey-light overflow-hidden mb:top-auto mb:bottom-11"
          style={{ boxShadow: '0 4px 4px 0 rgba(34, 34, 34, 0.1)' }}
        >
          {LANG_OPTIONS.map((lang) => {
            return (
              <button
                key={lang}
                className={`${lang === i18n.language.slice(0, 2) && 'font-bold'} w-full h-10 pl-4 py-2.5 text-left text-sm text-grey-dark hover:bg-pale hover:bg-opacity-20`}
                onClick={() => changeLangHandler(lang)}
              >
                {getLanguageText(lang)}
              </button>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default HeaderLangs
