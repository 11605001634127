import { useTranslation } from 'react-i18next'

import Image from '../../../components/UI/Image'

function InfoBanner() {
  const { t } = useTranslation()

  return (
    <div>
      <div className="max-w-screen-xl mx-auto px-10 flex justify-between items-center overflow-hidden h-98 mb:h-96 mb:px-5 mb:items-start mb:relative">
        <div>
          <h3 className="font-bold mb-4.5 text-3.5xl mb:mb-3 mb:text-xl mb:mt-12">{t('What is FINIX token?')}</h3>
          <p className="text-grey-medium w-125 mb:w-80 mb:text-sm">{t('FINIX is a ecosystem token')}</p>
        </div>

        <Image
          name="info-banner"
          alt="definix character"
          className="w-1/2 self-end mb:absolute mb:right-0 mb:bottom-0 sm:w-76 sm:h-50"
        />
      </div>
    </div>
  )
}

export default InfoBanner
