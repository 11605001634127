import Image from '../../../components/UI/Image'

function LandingChoiceItem2({ title, content, image }) {
  return (
    <div className="px-10 py-2 w-96 mb:w-80 mb:px-0">
      <Image name={image} alt={image} style={{ width: '100px', height: '100px' }} className="mx-auto mb-4.5" />

      <div>
        <h4 className="text-xl font-bold mb-2 mb:text-base">{title}</h4>
        <p className="text-grey-medium w-full mb:text-sm">{content}</p>
      </div>
    </div>
  )
}
export default LandingChoiceItem2
