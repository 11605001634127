import { ReactComponent as CloseIcon } from '../../images/ui/ico-24-close.svg'
import { ReactComponent as MenuIcon } from '../../images/ui/ico-24-menu.svg'

function UIButton({ className, type, onClick }) {
  let iconImage

  switch (type) {
    case 'close':
      iconImage = <CloseIcon />
      break
    case 'menu':
      iconImage = <MenuIcon />
      break
    default:
      break
  }

  return (
    <button className={`hidden mb:block absolute ${className}`} onClick={onClick}>
      {iconImage}
    </button>
  )
}

export default UIButton
