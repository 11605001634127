import React from 'react'

import LandingBanner from './components/LandingBanner'
import LandingChoice from './components/LandingChoice'
import LandingV1 from './components/LandingV1'
import LandingOverview from './components/LandingOverview'
// import LandingMilestone from './components/LandingMilestone'
// import LandingFooterSpace from './components/LandingFooterSpace'

function LandingPage() {
  return (
    <main>
      <LandingBanner />

      <LandingChoice />

      <LandingV1 />

      <LandingOverview />

      {/* 마일스톤 표시하지 않을 경우 <LandingFooterSpace>를,
      표시할 경우 <LandingMilestone>을 사용 */}
      {/* <LandingMilestone /> */}
      {/* <LandingFooterSpace /> */}
    </main>
  )
}

export default LandingPage
