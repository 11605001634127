import { useTranslation } from 'react-i18next'

import Image from '../../../components/UI/Image'
import Card from '../../../components/UI/Card'
import LinkButton from '../../../components/UI/LinkButton'

function LandingV1Item({ title, content, image, isActive, href }) {
  const { t } = useTranslation()

  const buttonText = isActive ? t('Launch App') : t('Coming soon')

  return (
    <Card className="h-auto flex items-center justify-between mb:flex-col-reverse mb:pb-6 mb:pt-6">
      <div className="w-full h-full flex flex-col justify-between">
        <h4 className="text-xl font-bold pb-2 mb:text-base mb:max-w-full">{title}</h4>
        <p className="text-grey-medium flex-1 w-92 mb-6 mb:mb-5 mb:text-sm mb:max-w-full">{content}</p>

        <LinkButton
          href={href}
          className={`mb:w-full ${isActive ? 'bg-brown' : 'pointer-events-none bg-grey-light text-opacity-50'}`}
          text={buttonText}
        />
      </div>
      <Image name={image} alt={image} style={{ width: '100px', height: '100px' }} className="mb:mb-3.5" />
    </Card>
  )
}

export default LandingV1Item
