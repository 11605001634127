import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Image from '../UI/Image'
import FooterItem from './FooterItem'

function Footer() {
  const { t, i18n } = useTranslation()
  return (
    <footer className="mb:relative after:hidden mb:after:block after:bg-grey-light after:absolute after:top-16 after:w-full after:h-px">
      <div className="flex justify-between max-w-screen-xl mx-auto h-34 pt-8 px-10 mb:h-auto mb:p-10 mb:pt-0 mb:flex-col-reverse">
        <div className="flex mb:flex-col">
          <div className="flex mr-6 relative">
            <Image
              name="footer-six-logo"
              alt="SIX logo"
              className="box-content w-21 h-12 pr-5 mb:pr-4 mb:mt-5 mb:mx-0 mb:mb-2 mb:w-12 mb:h-7"
            />
            {/* <Image name="footer-fingerlabs-logo" alt="FingerLabs logo" className="box-content w-21 h-12 ml-5 mb:ml-4 mb:mt-5 mb:mx-0 mb:mb-2 mb:w-12 mb:h-7" /> */}
          </div>

          <div className="text-grey-medium text-sm mb:text-xs">
            <p className="mb-1.5 mb:mb-0.5">
              <Trans i18nKey="copyright" components={[<span className="font-bold">six network</span>]}></Trans>
            </p>
            <p>
              <Trans i18nKey="audit" components={[<span className="font-bold">certik</span>]}></Trans>
              <span
                style={{
                  color: "#ffffff",
                  marginLeft: "5px",
                }}
              >
                {process.env.REACT_APP_VERSION || 'v0.0.1'}
              </span>
            </p>
          </div>
        </div>

        <ul className="flex mt-3 text-primary mb:mt-0 mb:py-5 mb:justify-between">
          <FooterItem name="facebook" />
          <FooterItem name="twitter" />
          <FooterItem name="telegram" />
          <FooterItem name="kakaotalk" />
          <FooterItem name="gitbook" />
          <FooterItem name="github" />
          <FooterItem name="reddit" />
        </ul>
      </div>
    </footer>
  )
}
export default Footer
