import InfoBanner from './components/InfoBanner'
import InfoContent from './components/InfoContent'

function InfoPage() {
  return (
    <main>
      <InfoBanner />
      <InfoContent />
    </main>
  )
}
export default InfoPage
