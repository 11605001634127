import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function HeaderNavigation({ onClick }) {
  const { t } = useTranslation()

  return (
    <ul className="flex text-grey-dark font-medium mb:block mb:pt-18">
      <li className="mb:mb-2 ">
        <NavLink
          to="/info"
          className="px-8.5 py-2.5 rounded-2.5xl hover:opacity-70 mb:inline-block mb:px-4 mb:py-3"
          activeClassName="text-primary"
          onClick={onClick}
        >
          {t('What is FINIX token?')}
        </NavLink>
      </li>
      <li className="">
        <a
          href="https://bridge.six.network/"
          className="px-8.5 py-2.5 rounded-2.5xl hover:opacity-70 mb:inline-block mb:px-4 mb:py-3"
        >
          {t('SIX Bridge')}
        </a>
      </li>
    </ul>
  )
}

export default HeaderNavigation
