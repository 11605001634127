import { useTranslation } from 'react-i18next'

function LinkButton({ className, text, href }) {
  const { t } = useTranslation()

  return (
    <a
      className={`relative overflow-hidden flex justify-center items-center py-2.5 px-5 w-max bg-primary text-white rounded-lg text-sm font-bold hover:after:bg-white hover:after:opacity-20 hover:after:absolute hover:after:top-0 hover:after:w-full hover:after:h-full ${className}`}
      href={href || process.env.REACT_APP_V2_LINK}
    >
      {text || t('Launch App')}
    </a>
  )
}

export default LinkButton
