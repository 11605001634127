import { useTranslation } from 'react-i18next'

import InfoItem from './InfoItem'

function InfoContent() {
  const { t } = useTranslation()

  return (
    <section className="bg-yellow-medium flex flex-wrap justify-center gap-6 mb:gap-4 px-10 pt-14 pb-30 mb:px-5 mb:pt-8 mb:pb-20">
      <InfoItem
        title={t('Investment Privilege')}
        content={t('FINIX token provides extra privilege')}
        image="info-investment"
      />
      <InfoItem title={t('Ecosystem Token')} content={t('FINIX is a token that developers')} image="info-ecosystem" />
      <InfoItem title={t('FINIX Challenge')} content={t('FINIX token is used as a resource')} image="info-challenge" />
    </section>
  )
}

export default InfoContent
