import { Trans, useTranslation } from 'react-i18next'

import Image from '../../../components/UI/Image'
import LandingSectionContainer from './LandingSectionContainer'

function LandingOverview() {
  const { i18n } = useTranslation()
  let overviewImage;

  switch (i18n.language.slice(0, 2)) {
    case 'ko':
      overviewImage = 'landing-overview-ko';
      break;
      // case 'th':
      // overviewImage = 'landing-overview-th';
      // break;
      default:
      overviewImage = 'landing-overview-en';
      break;
  }

  return (
    <LandingSectionContainer className="bg-yellow-light">
      <h3 className="text-3xl font-bold mb-10 mb:text-xl mb:mb-6">
        <Trans i18nKey="Definix Overview" components={[<span className="text-sub">overview</span>]}></Trans>
      </h3>

      {<Image name={overviewImage} alt="definix overview" className="w-full h-auto" />}

    </LandingSectionContainer>
  )
}

export default LandingOverview
