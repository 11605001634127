function Card({ className, children }) {
  return (
    <div
      className={`bg-white relative p-10 w-140 rounded-2xl overflow-hidden border border-yellow-medium border-solid mb:px-6 mb:w-full mb:h-auto mb:pb-0 shadow ${className}`}
    >
      {children}
    </div>
  )
}

export default Card
