import { Trans, useTranslation } from 'react-i18next'

import LandingV1Item from './LandingV1Item'
import LandingSectionContainer from './LandingSectionContainer'

function LandingV1() {
  const { t } = useTranslation()

  return (
    <LandingSectionContainer className="bg-yellow-medium">
      <h3 className="text-3xl font-bold mb-10 mb:text-xl mb:mb-6">
        <Trans i18nKey="Say hello to Definix V1" components={[<span className="text-sub">definix v1</span>]}></Trans>
      </h3>

      <div className="flex flex-wrap justify-center gap-6 mb-15">
        <LandingV1Item
          title={t('DEX and Liquidity Pools')}
          content={t('Decentralized exchange (DEX) is a cryptocurrency')}
          isActive={true}
          image="landing-v1-dex"
          href={`${process.env.REACT_APP_V2_LINK}/swap`}
        />
        <LandingV1Item
          title={t('Farms')}
          content={t('You can farm your LP tokens')}
          isActive={true}
          image="landing-v1-farms"
          href={`${process.env.REACT_APP_V2_LINK}/farm`}
        />
        <LandingV1Item
          title={t('Crypto Fund Investment')}
          content={t('The system will evaluate your risk')}
          isActive={false}
          image="landing-v1-crypto"
          href=""
        />
        <LandingV1Item
          title={t('For Professional Traders')}
          content={t('You can request for Finix trading challenge')}
          isActive={false}
          image="landing-v1-traders"
          href=""
        />
      </div>
    </LandingSectionContainer>
  )
}

export default LandingV1
