import Header_Logo1x from '../../images/logo/header/logo-main-definix-img-text.png'
import Header_Logo2x from '../../images/logo/header/logo-main-definix-img-text@2x.png'
import Header_Logo3x from '../../images/logo/header/logo-main-definix-img-text@3x.png'
import Header_TextLogo1x from '../../images/logo/header/logo-main-definix-text.png'
import Header_TextLogo2x from '../../images/logo/header/logo-main-definix-text@2x.png'
import Header_TextLogo3x from '../../images/logo/header/logo-main-definix-text@3x.png'
import Footer_Six_Logo1x from '../../images/logo/footer/logo-footer-six.png'
import Footer_Six_Logo2x from '../../images/logo/footer/logo-footer-six@2x.png'
import Footer_Six_Logo3x from '../../images/logo/footer/logo-footer-six@3x.png'
import Footer_Fingerlabs_Logo1x from '../../images/logo/footer/logo-footer-fingerlabs.png'
import Footer_Fingerlabs_Logo2x from '../../images/logo/footer/logo-footer-fingerlabs@2x.png'
import Footer_Fingerlabs_Logo3x from '../../images/logo/footer/logo-footer-fingerlabs@3x.png'
import InfoBannerImage1x from '../../images/info-page/banner/img-intro-banner-token.png'
import InfoBannerImage2x from '../../images/info-page/banner/img-intro-banner-token@2x.png'
import InfoBannerImage3x from '../../images/info-page/banner/img-intro-banner-token@3x.png'
import Info_Investment1x from '../../images/info-page/token/img-intro-token-investment.png'
import Info_Investment2x from '../../images/info-page/token/img-intro-token-investment@2x.png'
import Info_Investment3x from '../../images/info-page/token/img-intro-token-investment@3x.png'
import InfoEcosystem1x from '../../images/info-page/token/img-intro-token-ecosystem.png'
import InfoEcosystem2x from '../../images/info-page/token/img-intro-token-ecosystem@2x.png'
import InfoEcosystem3x from '../../images/info-page/token/img-intro-token-ecosystem@3x.png'
import InfoChallange1x from '../../images/info-page/token/img-intro-token-challenge.png'
import InfoChallange2x from '../../images/info-page/token/img-intro-token-challenge@2x.png'
import InfoChallange3x from '../../images/info-page/token/img-intro-token-challenge@3x.png'
import LandingBannerImage1x from '../../images/landing-page/banner/img-intro.png'
import LandingBannerImage2x from '../../images/landing-page/banner/img-intro@2x.png'
import LandingBannerImage3x from '../../images/landing-page/banner/img-intro@3x.png'
import LandingChoice_1_Crypto1x from '../../images/landing-page/choice/img-intro-choice-1-crypto.png'
import LandingChoice_1_Crypto2x from '../../images/landing-page/choice/img-intro-choice-1-crypto@2x.png'
import LandingChoice_1_Crypto3x from '../../images/landing-page/choice/img-intro-choice-1-crypto@3x.png'
import LandingChoice_1_Traders1x from '../../images/landing-page/choice/img-intro-choice-1-traders.png'
import LandingChoice_1_Traders2x from '../../images/landing-page/choice/img-intro-choice-1-traders@2x.png'
import LandingChoice_1_Traders3x from '../../images/landing-page/choice/img-intro-choice-1-traders@3x.png'
import LandingChoice_2_Liquidity1x from '../../images/landing-page/choice/img-intro-choice-2-liquidity.png'
import LandingChoice_2_Liquidity2x from '../../images/landing-page/choice/img-intro-choice-2-liquidity@2x.png'
import LandingChoice_2_Liquidity3x from '../../images/landing-page/choice/img-intro-choice-2-liquidity@3x.png'
import LandingChoice_2_Investment1x from '../../images/landing-page/choice/img-intro-choice-2-invest.png'
import LandingChoice_2_Investment2x from '../../images/landing-page/choice/img-intro-choice-2-invest@2x.png'
import LandingChoice_2_Investment3x from '../../images/landing-page/choice/img-intro-choice-2-invest@3x.png'
import LandingChoice_2_Crypto1x from '../../images/landing-page/choice/img-intro-choice-2-crypto.png'
import LandingChoice_2_Crypto2x from '../../images/landing-page/choice/img-intro-choice-2-crypto@2x.png'
import LandingChoice_2_Crypto3x from '../../images/landing-page/choice/img-intro-choice-2-crypto@3x.png'
import LandingV1_Dex1x from '../../images/landing-page/v1/img-intro-v-1-dex.png'
import LandingV1_Dex2x from '../../images/landing-page/v1/img-intro-v-1-dex@2x.png'
import LandingV1_Dex3x from '../../images/landing-page/v1/img-intro-v-1-dex@3x.png'
import LandingV1_Farms1x from '../../images/landing-page/v1/img-intro-v-1-farms.png'
import LandingV1_Farms2x from '../../images/landing-page/v1/img-intro-v-1-farms@2x.png'
import LandingV1_Farms3x from '../../images/landing-page/v1/img-intro-v-1-farms@3x.png'
import LandingV1_Crypto1x from '../../images/landing-page/v1/img-intro-v-1-crypto.png'
import LandingV1_Crypto2x from '../../images/landing-page/v1/img-intro-v-1-crypto@2x.png'
import LandingV1_Crypto3x from '../../images/landing-page/v1/img-intro-v-1-crypto@3x.png'
import LandingV1_Traders1x from '../../images/landing-page/v1/img-intro-v-1-traders.png'
import LandingV1_Traders2x from '../../images/landing-page/v1/img-intro-v-1-traders@2x.png'
import LandingV1_Traders3x from '../../images/landing-page/v1/img-intro-v-1-traders@3x.png'
import LandingOverviewEn1x from '../../images/landing-page/overview/img-intro-overview-en.png'
import LandingOverviewEn2x from '../../images/landing-page/overview/img-intro-overview-en@2x.png'
import LandingOverviewEn3x from '../../images/landing-page/overview/img-intro-overview-en@3x.png'
import LandingOverviewKo1x from '../../images/landing-page/overview/img-intro-overview-ko.png'
import LandingOverviewKo2x from '../../images/landing-page/overview/img-intro-overview-ko@2x.png'
import LandingOverviewKo3x from '../../images/landing-page/overview/img-intro-overview-ko@3x.png'

function Image({ name, alt, className, style }) {
  let imageSrcset
  let imageSrc

  switch (name) {
    case 'header-logo':
      imageSrcset = (Header_Logo1x, Header_Logo2x, Header_Logo3x)
      imageSrc = Header_Logo3x
      break
    case 'header-textlogo':
      imageSrcset = (Header_TextLogo1x, Header_TextLogo2x, Header_TextLogo3x)
      imageSrc = Header_TextLogo3x
      break
    case 'footer-six-logo':
      imageSrcset = (Footer_Six_Logo1x, Footer_Six_Logo2x, Footer_Six_Logo3x)
      imageSrc = Footer_Six_Logo3x
      break
    case 'footer-fingerlabs-logo':
      imageSrcset = (Footer_Fingerlabs_Logo1x, Footer_Fingerlabs_Logo2x, Footer_Fingerlabs_Logo3x)
      imageSrc = Footer_Fingerlabs_Logo3x
      break
    case 'info-banner':
      imageSrcset = (InfoBannerImage1x, InfoBannerImage2x, InfoBannerImage3x)
      imageSrc = InfoBannerImage3x
      break
    case 'info-investment':
      imageSrcset = (Info_Investment1x, Info_Investment2x, Info_Investment3x)
      imageSrc = Info_Investment3x
      break
    case 'info-ecosystem':
      imageSrcset = (InfoEcosystem1x, InfoEcosystem2x, InfoEcosystem3x)
      imageSrc = InfoEcosystem3x
      break
    case 'info-challenge':
      imageSrcset = (InfoChallange1x, InfoChallange2x, InfoChallange3x)
      imageSrc = InfoChallange3x
      break
    case 'landing-banner':
      imageSrcset = (LandingBannerImage1x, LandingBannerImage2x, LandingBannerImage3x)
      imageSrc = LandingBannerImage3x
      break
    case 'landing-choice-1-crypto':
      imageSrcset = (LandingChoice_1_Crypto1x, LandingChoice_1_Crypto2x, LandingChoice_1_Crypto3x)
      imageSrc = LandingChoice_1_Crypto3x
      break
    case 'landing-choice-1-traders':
      imageSrcset = (LandingChoice_1_Traders1x, LandingChoice_1_Traders2x, LandingChoice_1_Traders3x)
      imageSrc = LandingChoice_1_Traders3x
      break
    case 'landing-choice-2-liquidity':
      imageSrcset = (LandingChoice_2_Liquidity1x, LandingChoice_2_Liquidity2x, LandingChoice_2_Liquidity3x)
      imageSrc = LandingChoice_2_Liquidity3x
      break
    case 'landing-choice-2-investment':
      imageSrcset = (LandingChoice_2_Investment1x, LandingChoice_2_Investment2x, LandingChoice_2_Investment3x)
      imageSrc = LandingChoice_2_Investment3x
      break
    case 'landing-choice-2-crypto':
      imageSrcset = (LandingChoice_2_Crypto1x, LandingChoice_2_Crypto2x, LandingChoice_2_Crypto3x)
      imageSrc = LandingChoice_2_Crypto3x
      break
    case 'landing-v1-dex':
      imageSrcset = (LandingV1_Dex1x, LandingV1_Dex2x, LandingV1_Dex3x)
      imageSrc = LandingV1_Dex3x
      break
    case 'landing-v1-farms':
      imageSrcset = (LandingV1_Farms1x, LandingV1_Farms2x, LandingV1_Farms3x)
      imageSrc = LandingV1_Farms3x
      break
    case 'landing-v1-crypto':
      imageSrcset = (LandingV1_Crypto1x, LandingV1_Crypto2x, LandingV1_Crypto3x)
      imageSrc = LandingV1_Crypto3x
      break
    case 'landing-v1-traders':
      imageSrcset = (LandingV1_Traders1x, LandingV1_Traders2x, LandingV1_Traders3x)
      imageSrc = LandingV1_Traders3x
      break
    case 'landing-overview-en':
      imageSrcset = (LandingOverviewEn1x, LandingOverviewEn2x, LandingOverviewEn3x)
      imageSrc = LandingOverviewEn3x
      break
    case 'landing-overview-ko':
      imageSrcset = (LandingOverviewKo1x, LandingOverviewKo2x, LandingOverviewKo3x)
      imageSrc = LandingOverviewKo3x
      break
    // case 'landing-overview-th':
    //   imageSrcset = (LandingOverviewTh1x, LandingOverviewTh2x, LandingOverviewTh3x)
    //   imageSrc = LandingOverviewTh3x
    //   break
    default:
      break
  }

  return <img srcSet={imageSrcset} src={imageSrc} alt={alt} style={style} className={className} />
}

export default Image
