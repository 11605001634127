import Image from '../../../components/UI/Image'
import Card from '../../../components/UI/Card'

function LandingChoiceItem1({ title, image, content, content2 }) {
  return (
    <Card className="h-65 mb:h-65 sm:h-auto sm:pr-0">
      <div className="mb:w-full sm:pr-5">
        <h4 className="text-xl font-bold pb-2 mb:text-base mb:max-w-full">{title}</h4>
        <p className="text-grey-medium w-92 mb:text-sm mb:max-w-full">{content}</p>
        {content2 && (
          <p className="text-grey-medium mt-1.5 w-66 text-sm mb:text-xs mb:mb-2.5 mb:max-w-full">{content2}</p>
        )}
      </div>

      <Image
        name={image}
        alt="definix character"
        className="w-58 h-36 absolute bottom-0 right-0 sm:relative sm:float-right sm:w-42 sm:h-26"
      />
    </Card>
  )
}

export default LandingChoiceItem1
