import Image from '../../../components/UI/Image'

function InfoItem({ title, content, image }) {
  return (
    <div className="bg-white shadow flex flex-col w-96 mb:w-full items-center rounded-2xl p-10 border border-yellow-medium">
      <Image name={image} alt={image} style={{ width: '100px', height: '100px' }} className="mb-4.5 mb:mb-4" />

      <div className="w-full">
        <h3 className="mb-2 font-bold text-xl mb:text-base mb:mb-1.5">{title}</h3>
        <p className="text-grey-medium leading-6 mb:text-sm">{content}</p>
      </div>
    </div>
  )
}

export default InfoItem
