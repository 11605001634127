import React from 'react'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import ScrollToTop from './components/UI/ScrollToTop'
import InfoPage from './pages/InfoPage/index'
import LandingPage from './pages/LandingPage/index'
// import Store from './store/lang';
import './stylesheets/app.scss'

ReactGA.initialize('G-L997LXLF8F')

const tagManagerArgs = {
  gtmId: 'GTM-WVFPW42',
}
TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <Router>
      <Header />

      <ScrollToTop />
      <Switch>
        <Route path="/info" exact component={InfoPage} />
        <Route path="/" exact component={LandingPage} />
        {/* <Redirect to="/en" /> */}
      </Switch>

      <Footer />
    </Router>
  )
}

export default App
