import { useTranslation } from 'react-i18next'

import Image from '../../../components/UI/Image'
import LinkButton from '../../../components/UI/LinkButton'

function LandingBanner() {
  const { t } = useTranslation()

  return (
    <div>
      <div className="max-w-screen-xl px-10 mx-auto flex justify-between items-center overflow-hidden h-148 mb:h-110 mb:px-5 mb:items-start mb:relative">
        <div>
          <h3 className="font-bold text-3.5xl mb:text-xl mb:mt-12">{t('The Decentralized Multi-chain')}</h3>
          <h3 className="text-sub font-bold mb-4.5 text-3.5xl mb:text-xl mb:mb-2.5">{t('Fund Management Protocol')}</h3>
          <p className="text-grey-medium w-125 mb-12 mb:w-80 mb:text-sm mb:mb-7.5">
            {t('Definix is the transparent crypto')}
          </p>
          <LinkButton className="h-12 w-46 text-base mb:mb-5 mb:h-10" />
        </div>

        <Image
          name="landing-banner"
          alt="definix character"
          className="w-1/2 mb:absolute mb:right-0 mb:bottom-0 sm:-right-11.5 sm:-bottom-5.5 sm:w-76 sm:h-50"
        />
      </div>
    </div>
  )
}

export default LandingBanner
